import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaArrowUp } from 'react-icons/fa';
import product1 from "../../assets/images/product1.jpg";
import product2 from "../../assets/images/product1.jpg";
import product3 from "../../assets/images/product1.jpg";
import product4 from "../../assets/images/product1.jpg";
import product5 from "../../assets/images/product1.jpg";
import product6 from "../../assets/images/product1.jpg";
import product from "../../assets/images/banner1.jpg"
import { green } from '@mui/material/colors';
const Shop = () => {
  const Bars = [
    {
      title: 'Mexican Steak With',
      description: 'Chicken, whole Mushrooms, siracha sauce, tomato ketchup, oyster sauce, vinegar, Extra virgin Olive',
      price: '560',
      imgSrc: product1,
    },
    {
      title: 'Moroccan Steak',
      description: 'Chicken, Extra virgin Olive Oil, capsicum, whole Mushrooms, green chili, yogurt, cream, red chili',
      price: '540',
      imgSrc: product2,
    },
  ];

  const mgf = [
    {
      title: 'Multi-Grain Flour',
      description: 'A blend of various grains to give you the best in nutrition and taste.',
      price: '250',
      imgSrc: product3,
    },
    {
      title: 'Whole Wheat Flour',
      description: 'Pure and organic whole wheat flour for a healthy meal.',
      price: '300',
      imgSrc: product4,
    },
  ];

  const s = [
    {
      title: 'Peanut Butter Spread',
      description: 'Creamy and rich peanut butter made from organic peanuts.',
      price: '450',
      imgSrc: product5,
    },
    {
      title: 'Chocolate Hazelnut Spread',
      description: 'Delicious chocolate spread with a hint of hazelnut.',
      price: '500',
      imgSrc: product6,
    },
  ];

  const mb = [
    {
      title: 'Pancake Mixture',
      description: 'Ready-to-make pancake mixture, just add water or milk.',
      price: '350',
      imgSrc: product3,
    },
    {
      title: 'Cake Batter Mix',
      description: 'Perfectly balanced cake batter mix for soft and fluffy cakes.',
      price: '400',
      imgSrc: product4,
    },
  ];

  const tea = [
    {
      title: 'Green Tea',
      description: 'Organic green tea leaves for a refreshing start to your day.',
      price: '150',
      imgSrc: product5,
    },
    {
      title: 'Black Tea',
      description: 'Strong and flavorful black tea, sourced from the finest gardens.',
      price: '200',
      imgSrc: product6,
    },
  ];

  const [openSection, setOpenSection] = useState(null);
  const [showScroll, setShowScroll] = useState(false);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 300) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 300) {
        setShowScroll(false);
      }
    };
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className="container">
        <div className="sidebar">
          <div className="cart-info">
            <p>Your Cart:</p>
            <p>Rs. 0</p>
          </div>
          <ul className="menu">
            <li><i className="fas fa-fire" style={{ color: '#ff5733' }}></i> Popular</li>
            <li><i className="fas fa-cookie" style={{ color: '#ffa500' }}></i> Bars</li>
            <li><i className="fas fa-bread-slice" style={{ color: '#ffcc00' }}></i> Multi-Grain Flour</li>
            <li><i className="fas fa-cheese" style={{ color: '#ffd700' }}></i> Spread</li>
            <li><i className="fas fa-mortar-pestle" style={{ color: '#8b4513' }}></i> Mixture Batter</li>
            <li><i className="fas fa-mug-hot" style={{ color: '#800000' }}></i> Tea</li>
          </ul>
          <div className="contact">
            <p>Call Now! (0306 01 01 01 8)</p>
          </div>
          <div className="social-media">
            <a href="#"><i className="fab fa-whatsapp"></i></a>
            <a href="#"><i className="fab fa-facebook"></i></a>
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-youtube"></i></a>
          </div>
          <div className="footer">
            <p>Copyright © 2024 Nutriwell. All rights reserved.</p>
          </div>
        </div>

        <div className="main-content">
          <h1>Healthy Meals</h1>
          <h2>Order Now Online!</h2>
        </div>

        <div className="bars-section">
          <div className="bar-image">
            <h1>Bars</h1>
          </div>
          <div className="bars-list">
            {Bars.map((bar, index) => (
              <div className="bars-item" key={index}>
                <img src={bar.imgSrc} alt={bar.title} />
                <h3>{bar.title}</h3>
                <p>{bar.description}</p>
                <button>Buy Now For Rs. {bar.price}</button>
              </div>
            ))}
          </div>
        </div>

        <div className="bars-section2">
          <div className="bar-image2">
            <h1>Multi-Grain Flour</h1>
          </div>
          <div className="bars-list2">
            {mgf.map((item, index) => (
              <div className="bars-item2" key={index}>
                <img src={item.imgSrc} alt={item.title} />
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <button>Buy Now For Rs. {item.price}</button>
              </div>
            ))}
          </div>
        </div>

        <div className="bars-section">
          <div className="bar-image">
            <h1>Spread</h1>
          </div>
          <div className="bars-list">
            {s.map((item, index) => (
              <div className="bars-item" key={index}>
                <img src={item.imgSrc} alt={item.title} />
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <button>Buy Now For Rs. {item.price}</button>
              </div>
            ))}
          </div>
        </div>

        <div className="bars-section2">
          <div className="bar-image2">
            <h1>Mixture Batter</h1>
          </div>
          <div className="bars-list2">
            {mb.map((item, index) => (
              <div className="bars-item2" key={index}>
                <img src={item.imgSrc} alt={item.title} />
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <button>Buy Now For Rs. {item.price}</button>
              </div>
            ))}
          </div>
        </div>

        <div className="bars-section">
          <div className="bar-image">
            <h1>Tea</h1>
          </div>
          <div className="bars-list">
            {tea.map((item, index) => (
              <div className="bars-item" key={index}>
                <img src={item.imgSrc} alt={item.title} />
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <button>Buy Now For Rs. {item.price}</button>
              </div>
            ))}
          </div>
        </div>

        <div className="delivery">
          <p>Free Delivery in your City<span>On Orders Over 2000 PKR</span></p>
        </div>
      </div>

      <div className="whatsapp-button">
        <a href="https://wa.me/92305676778">
          <FaWhatsapp size={20} color="white" enableBackground={green}/>
        </a>
      </div>

      {showScroll && (
        <div className="up-button" onClick={scrollToTop} style={{ display: showScroll ? 'flex' : 'none' }}>
        <FaArrowUp size={20} color="white" />
      </div>
      )}
    </>
  );
};

export default Shop;
