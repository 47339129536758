import React from 'react';
import Footer from '../../Components/Footer';
import ContactUs from '../../Components/ContactUs';

const ContactUsPage = () => {
  
  return (
    <div>
      <ContactUs/>
      
      
      <Footer />
    </div>
  );
};

export default ContactUsPage;
