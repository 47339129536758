import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom"; // Import Link from React Router
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import bannerImage from "../../assets/images/banner1.jpg";
import banner1Image from "../../assets/images/banner2.jpg";
import banner2Image from "../../assets/images/banner3.jpg";
import banner3Image from "../../assets/images/banner4.jpg";

const HomeBanner = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    autoplay: true,
  };

  return (
    <div className='homebannerSection'>
      <Slider {...settings}>
        <div className="item">
          <img src={bannerImage} className="bannerImage" alt="Banner 1" />
          <div className="overlay">
            <h2>Live a Happier Lifestyle</h2>
            <h1>Reduce Anxiety and Depression</h1>
            <Link to="/guides-blogs" className="cta-button">Get Started</Link> {/* Link to Guides and News */}
          </div>
        </div>
        <div className="item">
          <img src={banner1Image} className="bannerImage" alt="Banner 2" />
          <div className="overlay">
            <h2>Live a Happier Lifestyle</h2>
            <h1>Reduce Anxiety and Depression</h1>
            <Link to="/guides-blogs" className="cta-button">Get Started</Link> {/* Link to Guides and News */}
          </div>
        </div>
        <div className="item">
          <img src={banner2Image} className="bannerImage" alt="Banner 3" />
          <div className="overlay">
            <h2>Live a Happier Lifestyle</h2>
            <h1>Reduce Anxiety and Depression</h1>
            <Link to="/guides-blogs" className="cta-button">Get Started</Link> {/* Link to Guides and News */}
          </div>
        </div>
        <div className="item">
          <img src={banner3Image} className="bannerImage" alt="Banner 4" />
          <div className="overlay">
            <h2>Live a Happier Lifestyle</h2>
            <h1>Reduce Anxiety and Depression</h1>
            <Link to="/guides-blogs" className="cta-button">Get Started</Link> {/* Link to Guides and News */}
          </div>
        </div>
      </Slider>
      <div class="wellness-section">
  <h2>A Better Way to Wellness</h2>
  <a href="/guides-blogs" class="cta-button">Learn the Benefits</a>
  <p>Fight sleeplessness, depression, and pain with the help of our products.</p>
</div>
</div>
    
  );
}

export default HomeBanner;
