import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import product from '../../assets/images/banner1.jpg'
const ProductInfo = () => {
  return (
    <div className="product-info-container">
      <div className="product-image">
        <img src={product} alt="Product" />
      </div>
      <div className="product-info">
        <h2>ABOUT OUR PRODUCTS</h2>
        <h1>
          <span>HOW</span> DO THEY WORK?
        </h1>
        <p>
          The human body has what’s called the endocannabinoid system, or ECS. This one system regulates many bodily functions, from inflammation to sleep. Our products react naturally with this system to make sure that it works more efficiently.
        </p>
        <Link to="/shop" className="explore-button">
          EXPLORE PRODUCTS
        </Link>
      </div>
    </div>
  );
};

export default ProductInfo;
