import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';

const BlogSection = () => {
  const blogs = [
    {
      title: "Latest trend in diet",
      date: "Sep 24, 2019",
      description: "In between the ever increasing trend to get the perfect beach body...",
      image: "path_to_image1.jpg",
      link: "/blog/latest-trend-in-diet",
    },
    {
      title: "Health and Healthy Food",
      date: "Sep 24, 2019",
      description: "Healthy food is directly proportional to healthy body and healthy mind...",
      image: "path_to_image2.jpg",
      link: "/blog/health-and-healthy-food",
    },
    {
      title: "Why Diet is a Need Nowadays",
      date: "Sep 6, 2019",
      description: "A balanced diet is basically the means to maintain or improve overall health...",
      image: "path_to_image3.jpg",
      link: "/blog/why-diet-is-a-need-nowadays",
    },
    // Add more blogs if needed
  ];

  return (
    <section className="blog-swiper">
      <h2 className="section-title">Our Latest Blogs</h2>
      <Swiper
        modules={[Pagination]}
        spaceBetween={20}
        slidesPerView={3}
        centeredSlides={true}
        loop={true}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            const blogNumber = (index % blogs.length) + 1; // Reset numbering after last blog
            return `<span class="${className} custom-bullet">${blogNumber}</span>`;
          },
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {blogs.map((blog, index) => (
          <SwiperSlide key={index}>
            <div className="blog-card">
              <img src={blog.image} alt={blog.title} />
              <Link to={blog.link}>
                <h3>{blog.title}</h3>
              </Link>
              <p className="date">{blog.date}</p>
              <p className="description">{blog.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default BlogSection;
