import React from 'react';

const ContactUs = () => {
  return (
    <div className="contact-container">
      <div className="contact-info">
        <h3>Consultant</h3>
        <h1>Contact <span>Now</span></h1>
        <hr />
        <p>You can contact us by sending us an email or with the given information. Our customer support is always ready to help you, thanks.</p>
        <ul>
          <li><i className="fas fa-map-marker-alt"></i> 64C 11th Commercial Street DHA Phase 2, Karachi, Pakistan</li>
          <li><i className="fas fa-phone-alt"></i> +92 332 222 3429</li>
          <li><i className="fas fa-envelope"></i> support@dietbox.pk</li>
        </ul>
      </div>
      <div className="contact-form">
        <form>
          <input type="text" placeholder="Your Name" required />
          <div className="form-group">
            <input type="text" placeholder="Phone" required />
            <input type="email" placeholder="Email" required />
          </div>
          <textarea placeholder="Your Message" required></textarea>
          <button type="submit"><i className="fas fa-envelope"></i> Send Message</button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
