import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import Routes
import Home from '../src/Pages/Home';
import Header from './Components/Header';
import Search from './Pages/Search';
import Profile from './Pages/profile';
import Cart from './Pages/cart';
import Shop from './Pages/Shop';
import PackagePricing from './Pages/PackagePricing';
import AssassinateWeight from './Pages/AssassinateWeight';
import NinjaPlan from './Pages/NinjaPlan';
import IntermittentFastingNoon from './Pages/IntermittentFastingNoon';
import About from './Pages/About';
import Work from './Pages/Work';
import Reviews from './Pages/Reviews';
import GuidesAndBlogs from './Pages/GuidesAndBlogs';
import ContactUsPage from './Pages/ContactUsPage';

function App() {
 
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/search" element={<Search/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/cart" element={<Cart/>} />
        <Route path="/shop" element={<Shop/>} />
        <Route path="/package-pricing" element={<PackagePricing />} />
        <Route path="/assassinate-weight" element={< AssassinateWeight/>} />
        <Route path="/ninja-plan" element={< NinjaPlan/>} />
        <Route path="/intermittent-fasting-noon" element={< IntermittentFastingNoon/>} />
        <Route path="/about" element={< About/>} />
        <Route path="/how-it-works" element={< Work/>} />
        <Route path="/reviews" element={< Reviews/>} />
        <Route path="/guides-blogs" element={< GuidesAndBlogs/>} />
        <Route path="/contact-us" element={< ContactUsPage/>} />

        
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;
