// src/Pages/Home/index.js
import React from 'react';
import HomeBanner from '../../Components/HomeBanner';
import 'swiper/css';
import 'swiper/css/navigation';
import Footer from '../../Components/Footer';
import BenefitsSection from '../../Components/Benefits Section';
import ProductInfo from '../../Components/ProductInfo';
import AboutDietBox from '../../Components/AboutDietBox';
import DietBoxSection from '../../Components/DietBoxSection';
import HowItWorks from '../../Components/HowItWorks';
import BlogSection from '../../Components/BlogSection';
import Testimonials from '../../Components/Testimonials';
import ContactUs from '../../Components/ContactUs';
import PackagePricingDetails from '../../Components/PakagePricingDetails';

const Home = () => {
  return (
    <>
      <HomeBanner />
      <BenefitsSection/>
      <AboutDietBox/>
      <DietBoxSection/>
      <ProductInfo/>
      <PackagePricingDetails/>
      <HowItWorks/>
      <BlogSection/>
      <Testimonials/>
      <ContactUs/>
      <Footer/>
      
    </>
  );
};

export default Home;
