import React from 'react';
import Footer from '../../Components/Footer';

const Cart = () => {
  return (
    <>
    <div className="cart-container">
      <h1>CART</h1>
      <p>Your cart is currently empty.</p>
      <a href="/shop" className="continue-shopping">
        Continue shopping
      </a>
    </div>
    <Footer/>
    </>
  );
};

export default Cart;
