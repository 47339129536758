import React from 'react';
import Footer from '../../Components/Footer';

const Profile = () => {
  return (
    <>
    <div className="signin-container">
      <h2 className="signin-title">PLEASE SIGN IN</h2>
      <div className="signin-box">
        <h3 className="signin-welcome">Welcome Back</h3>
        <p className="signin-subtitle">ENTER YOUR SIGN IN DETAILS</p>
        <form>
          <input
            type="email"
            placeholder="Email"
            className="signin-input"
            required
          />
          <input
            type="password"
            placeholder="password"
            className="signin-input"
            required
          />
          <a href="#" className="signin-forgot">
            Forgot password?
          </a>
          <button type="submit" className="signin-submit">
            SUBMIT
          </button>
        </form>
        <button className="signin-create-account">CREATE ACCOUNT</button>
      </div>




      
    </div>
   <Footer/>
   </>
  );
};

export default Profile;
