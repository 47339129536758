import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaArrowUp } from 'react-icons/fa';
import { RiFacebookCircleLine, RiTwitterLine, RiInstagramLine, RiYoutubeLine, RiTiktokLine } from 'react-icons/ri';

const Footer = () => {
  const [openSection, setOpenSection] = useState(null);
  const [showScroll, setShowScroll] = useState(false);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  // Show the scroll-up button when scrolled down 300px
  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 300) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 300) {
        setShowScroll(false);
      }
    };
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="footer">
      <div className="footer-section">
        <h3 onClick={() => toggleSection('contact')}>
          Contact Us <span className="toggle-btn">{openSection === 'contact' ? '-' : '+'}</span>
        </h3>
        <div className={`footer-content ${openSection === 'contact' ? 'show' : ''}`}>
          <p>Email Us: thenutriwell.official@gmail.com</p>
          <p>Call Us: +923056778858</p>
          <p>WhatsApp Us: +9230576464675</p>
        </div>
      </div>
      <div className="footer-section">
        <h3 onClick={() => toggleSection('customer')}>
          Customer Care <span className="toggle-btn">{openSection === 'customer' ? '-' : '+'}</span>
        </h3>
        <div className={`footer-content ${openSection === 'customer' ? 'show' : ''}`}>
          <ul>
            <li>My Account</li>
            <li>Contact Us</li>
            <li>FAQs</li>
          </ul>
        </div>
      </div>
      <div className="footer-section">
        <h3 onClick={() => toggleSection('information')}>
          Information <span className="toggle-btn">{openSection === 'information' ? '-' : '+'}</span>
        </h3>
        <div className={`footer-content ${openSection === 'information' ? 'show' : ''}`}>
          <ul>
            <li>About Us</li>
            <li>Stockists</li>
            <li>Wholesale</li>
            <li>Blogs</li>
          </ul>
        </div>
      </div>
      <div className="footer-section">
        <h3 onClick={() => toggleSection('policies')}>
          Policies <span className="toggle-btn">{openSection === 'policies' ? '-' : '+'}</span>
        </h3>
        <div className={`footer-content ${openSection === 'policies' ? 'show' : ''}`}>
          <ul>
            <li>Privacy Policy</li>
            <li>Shipping Policy</li>
            <li>Ordering & Tracking</li>
            <li>Return & Exchanges</li>
            <li>Terms & Conditions</li>
            <li>Cookies Policy</li>
          </ul>
        </div>
      </div>
      <div className="footer-section newsletter-section">
        <h2>Newsletter</h2>
        <input type="email" placeholder="Your email" />
        <button>Subscribe</button>
      </div>
      <div className="footer-social-media">
        <h3>Follow Us</h3>
        <div className="social-icons">
          <a href="#"><RiFacebookCircleLine /></a>
          <a href="#"><RiTwitterLine /></a>
          <a href="#"><RiInstagramLine /></a>
          <a href="#"><RiYoutubeLine /></a>
          <a href="#"><RiTiktokLine /></a>
        </div>
      </div>
      <div className="whatsapp-button">
        <a href="https://wa.me/92305676778">
          <FaWhatsapp size={20} color="white" backgroundcolor="green"/>
        </a>
      </div>
      <div className="up-button" onClick={scrollToTop} style={{ display: showScroll ? 'flex' : 'none' }}>
        <FaArrowUp size={20} color="white" />
      </div>
      <div className="footer-reserved">
        <p>Website Reserved © 2024</p>
      </div>
    </footer>
  );
}

export default Footer;
