import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {  Pagination } from 'swiper/modules';
import background from "../../assets/images/banner1.jpg"
const Testimonials = () => {
  const testimonials = [
    {
      text: "Chicken was cooked perfectly. Loved it!",
      name: "Jenaan Hussain",
      title: "Regular Customer",
    },
    {
        text: "Chicken was cooked perfectly. Loved it!",
        name: "Jenaan Hussain",
        title: "Regular Customer",
      },
      {
        text: "Chicken was cooked perfectly. Loved it!",
        name: "Jenaan Hussain",
        title: "Regular Customer",
      },
      {
        text: "Chicken was cooked perfectly. Loved it!",
        name: "Jenaan Hussain",
        title: "Regular Customer",
      },
      {
        text: "Chicken was cooked perfectly. Loved it!",
        name: "Jenaan Hussain",
        title: "Regular Customer",
      },
      {
        text: "Chicken was cooked perfectly. Loved it!",
        name: "Jenaan Hussain",
        title: "Regular Customer",
      },
      {
        text: "Chicken was cooked perfectly. Loved it!",
        name: "Jenaan Hussain",
        title: "Regular Customer",
      },
    // Add more testimonials here
  ];

  return (
    <Swiper
      modules={[Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      loop={true}
      autoplay={{ delay: 5000 }}
    >
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={index}>
          <div className="testimonial-slide">
            <div className="background-image">
              <img src={background} alt="Background" />
            </div>
            <div className="testimonial-content">
              <h2>What <strong>People</strong> Say ?</h2>
              <p>{testimonial.text}</p>
              <h4>{testimonial.name} - <span>{testimonial.title}</span></h4>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Testimonials;
