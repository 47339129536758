import React from 'react';
import { FaPaperPlane, FaPhoneAlt, FaCalendarCheck } from 'react-icons/fa';
import dietBoxImage from '../../assets/images/logo.png'; // Replace with the correct path to your image

const AboutDietBox = () => {
    return (
        <div className="dietbox-container">
            <h2>About Our <span>Diet Box</span></h2>
            <div className="dietbox-content">
                <div className="dietbox-item">
                    <FaPaperPlane className="dietbox-icon" />
                    <h3>Our Mission</h3>
                    <p>We are passionate to help you with weight loss in order to get the best shape ever and to live a healthy life with calculated nutritious food.</p>
                </div>
                <div className="dietbox-image">
                    <img src={dietBoxImage} alt="Diet Box" />
                </div>
                <div className="dietbox-item">
                    <FaPhoneAlt className="dietbox-icon" />
                    <h3>24/7 Help Support</h3>
                    <p>Our team of nutritionists is available 24/7 for customer queries and advice. You may reach us on <br />0332 222 3429</p>
                </div>
            </div>
            <div className="dietbox-item bottom-item">
                <FaCalendarCheck className="dietbox-icon" />
                <h3>Our Values</h3>
                <p>Our customers are our top priority and we are determined to provide top-quality and healthy food.</p>
            </div>
        </div>
    );
};

export default AboutDietBox;
