import React from 'react';

const HowItWorks = () => {
    return (
        <div className="how-it-works-container">
            <h2 className="how-it-works-title"><span>How</span> it Works</h2>
            <div className="timeline">
                <div className="timeline-item">
                    <div className="timeline-icon">1</div>
                    <div className="timeline-content">
                        <h3>Account Creation</h3>
                        <p>
                            <a href="/contact">Contact Us here</a> our representative will call you and explain all the process according to your need. You can also reach us by dialing 0332 222 3429.
                        </p>
                    </div>
                </div>
                <div className="timeline-item">
                    <div className="timeline-icon">2</div>
                    <div className="timeline-content2">
                        <h3>Payment Confirmation</h3>
                        <p>After finalizing the package, you can make a payment via bank transfer, or ask our representative for cash on delivery.</p>
                        <p><strong>Account Name:</strong> DIET BOX</p>
                        <p><strong>Bank:</strong> Meezan Bank Limited</p>
                        <p><strong>Account Num:</strong> 01390104137327</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
