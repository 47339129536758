import React from 'react';

const BenefitsSection = () => {
  return (
    <div className="benefits-section">
      <div className="benefit-card">
        <img src="/path-to-your-image/sleep-icon.png" alt="Improved Sleep" className="benefit-icon" />
        <h3>Improved Sleep</h3>
        <p>Fall asleep and stay asleep easily. Wake up feeling refreshed and ready to take on your day with a healthier sleep schedule.</p>
      </div>
      <div className="benefit-card">
        <img src="/path-to-your-image/anxiety-icon.png" alt="Reduce Anxiety and Depression" className="benefit-icon" />
        <h3>Reduce Anxiety and Depression</h3>
        <p>Regulate brain chemicals that trigger anxiety attacks or bouts of depression and reduce cortisol levels (the stress hormone).</p>
      </div>
      <div className="benefit-card">
        <img src="/path-to-your-image/pain-icon.png" alt="Ease Pain and Inflammation" className="benefit-icon" />
        <h3>Ease Pain and Inflammation</h3>
        <p>Recover faster and get back to doing what you love. Take your athletic performance to the next level and soothe your joints and muscles quicker.</p>
      </div>
      <div className="benefit-card">
        <img src="/path-to-your-image/energy-icon.png" alt="Boost Your Energy" className="benefit-icon" />
        <h3>Boost Your Energy</h3>
        <p>Activate your endocannabinoid system (ECS) to get an energy boost before your workout. Our product works naturally with the ECS to make sure it works more efficiently.</p>
      </div>
    </div>
  );
};

export default BenefitsSection;
