import React, { useState } from 'react';
import { FaBars, FaTimes, FaFacebookF, FaInstagram, FaUser, FaCartPlus, FaSearch, FaChevronDown } from 'react-icons/fa';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const toggleSubMenu = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  return (
    <header className="header-container">
      <div className="top-bar">
        <div className="branding">
          <span>GET PURE PRODUCTS</span>
        </div>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
        </div>
      </div>
      <nav className="nav-bar">
        <div className="branding">
          <img src={logo} alt="Thenutriwell" className="logo" />
        </div>
        <button className="mobile-toggle-button" onClick={toggleNav}>
          {isNavOpen ? <FaTimes /> : <FaBars />}
        </button>
        <div className={`nav-links ${isNavOpen ? 'open' : 'closed'}`}>
          <Link to="./" className="nav-link">Home</Link>
          <Link to="/shop" className="nav-link"> Our Shop</Link>
          <Link to="/package-pricing" className="nav-link"> Package Pricing</Link>
          <Link to="/how-it-works" className="nav-link"> How it work</Link>

          <Link to="/about" className="nav-link">About</Link>
          <Link to="/reviews" className="nav-link">Reviews</Link>

          <Link to="/guides-blogs" className="nav-link">Guides & Blogs</Link>
          <div 
            className="nav-item" 
            onClick={() => toggleSubMenu('promotions')}
          >
            <div className="nav-link">
              Promotions <FaChevronDown />
            </div>
            <div className={`submenu ${activeMenu === 'promotions' ? 'show' : ''}`}>
              <Link to="/sale" className="submenu-link">Sale</Link>
              <Link to="/ramadan-offer" className="submenu-link">Ramadan Offer</Link>
            </div>
          </div>
          <Link to="/contact-us" className="nav-link">Contact Us</Link>

          <div className="nav-icons">
            <Link to="/search">
              <FaSearch />
            </Link>
            <Link to="/profile">
              <FaUser />
            </Link>
            <Link to="/cart">
              <FaCartPlus />
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
