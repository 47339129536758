import React, { useState } from 'react';
import Footer from '../../Components/Footer';

const Search = () => {
    const [isSearchVisible, setIsSearchVisible] = useState(true);

    const handleSearchClose = () => {
        setIsSearchVisible(false);
    };

    return (
        <>
        <div className="wishlist-container">
            {isSearchVisible && (
                <div className="search-bar">
                    <input type="text" placeholder="Search our store" />
                    <span className="close-icon" onClick={handleSearchClose}>&times;</span>
                </div>
            )}
            
        </div>
        <Footer/>
        </>
    );
};

export default Search;
