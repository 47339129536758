import React from 'react';
import Footer from '../../Components/Footer';

import BenefitsSection from '../../Components/Benefits Section';
import BlogSection from '../../Components/BlogSection';

const GuidesAndBlogs = () => {
  
  return (
    <div>
        <BenefitsSection/>
      <BlogSection/>
      
      <Footer />
    </div>
  );
};

export default GuidesAndBlogs;
