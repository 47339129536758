import React from 'react';
import Footer from '../../Components/Footer';

import Testimonials from '../../Components/Testimonials';

const Reviews = () => {
  
  return (
    <div>
        <Testimonials/>
      
      
      <Footer />
    </div>
  );
};

export default Reviews;
