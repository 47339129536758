// src/Pages/Home/index.js
import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import Footer from '../../Components/Footer';

import PackagePricingDetails from '../../Components/PakagePricingDetails';


const PackagePricing = () => {
  return (
    <>
      
      <PackagePricingDetails/>
      
      <Footer/>
      
    </>
  );
};

export default PackagePricing;
