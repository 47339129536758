import React from 'react';
import { Link } from 'react-router-dom';

const PackagePricingDetails = () => {
    return (
        <div className="package-pricing-section">
            <h2>Our Promotion is Live <br /> <span>Package Pricing</span></h2>
            <div className="package-cards">
                <div className="package-card assassin-weight">
                    <Link to="/assassinate-weight">
                        <h3>Assassinate Weight</h3>
                    </Link>
                    <p className="price">Rs 29,000</p>
                    <p className="description">Best for all those who want to lose weight while eating healthy food</p>
                    <ul>
                        <li>30 Days</li>
                        <li>1200 Calories</li>
                        <li>Breakfast</li>
                        <li>Lunch</li>
                        <li>Snacks (Optional)</li>
                        <li>Dinner</li>
                        <li>Package Menu</li>
                    </ul>
                    <Link to="/cart" className="btn-add-to-cart">Add To Cart</Link>
                </div>
                <div className="package-card ninja-plan">
                    <Link to="/ninja-plan">
                        <h3>Ninja Plan</h3>
                    </Link>
                    <p className="price">Rs 15,000</p>
                    <p className="description">If you want to lose weight but are confused about the taste and quality of food</p>
                    <ul>
                        <li>15 Days</li>
                        <li>1200 Calories</li>
                        <li>Breakfast</li>
                        <li>Lunch</li>
                        <li>Snacks (Optional)</li>
                        <li>Dinner</li>
                        <li>Package Menu</li>
                    </ul>
                    <Link to="/cart" className="btn-add-to-cart">Add To Cart</Link>
                </div>
                <div className="package-card intermittent-fasting">
                    <Link to="/intermittent-fasting-noon">
                        <h3>Intermittent Fasting Noon</h3>
                    </Link>
                    <p className="price">Rs 15,000</p>
                    <p className="description">If you are a working person and want to eat a healthy lunch at work</p>
                    <ul>
                        <li>30 Days</li>
                        <li>450 Calories</li>
                        <li><s>Breakfast</s></li>
                        <li>Lunch</li>
                        <li>Snacks (Optional)</li>
                        <li><s>Dinner</s></li>
                        <li>Package Menu</li>
                    </ul>
                    <Link to="/cart" className="btn-add-to-cart">Add To Cart</Link>
                </div>
            </div>
        </div>
    );
};

export default PackagePricingDetails;
