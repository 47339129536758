import React from 'react';
import AboutDietBox from '../../Components/AboutDietBox';
import DietBoxSection from '../../Components/DietBoxSection';
import Footer from '../../Components/Footer';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'; // Import Font Awesome icons
import image1 from "../../assets/images/image1.jpg";

const About = () => {
  const teamMembers = [
    {
      name: "Full Name",
      role: "Designation",
      image: image1,
      social: {
        facebook: "#",
        twitter: "#",
        instagram: "#",
      },
    },
    {
      name: "Full Name",
      role: "Designation",
      image: image1,
      social: {
        facebook: "#",
        twitter: "#",
        instagram: "#",
      },
    },
    {
      name: "Full Name",
      role: "Designation",
      image: image1,
      social: {
        facebook: "#",
        twitter: "#",
        instagram: "#",
      },
    },
    {
      name: "Full Name",
      role: "Designation",
      image: image1,
      social: {
        facebook: "#",
        twitter: "#",
        instagram: "#",
      },
    },
  ];

  return (
    <div className="about-page p-8 max-w-5xl mx-auto">
      <AboutDietBox />
      <DietBoxSection />
      <div className="team-section">
        <h2 className="team-title">Meet Our Team</h2>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-member">
              <img
                src={member.image}
                alt={member.name}
                className="team-member-image"
              />
              <div className="social-icons">
                <a href={member.social.facebook} aria-label="Facebook">
                  <FaFacebookF />
                </a>
                <a href={member.social.twitter} aria-label="Twitter">
                  <FaTwitter />
                </a>
                <a href={member.social.instagram} aria-label="Instagram">
                  <FaInstagram />
                </a>
              </div>
              <div className="team-member-name">{member.name}</div>
              <div className="team-member-role">{member.role}</div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
