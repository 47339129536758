import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

const DietBoxSection = () => {
    const [hasAnimated, setHasAnimated] = useState({
        item1: false,
        item2: false,
        item3: false,
    });

    const handleAnimation = (item) => {
        setHasAnimated(prevState => ({ ...prevState, [item]: true }));
    };

    const { ref: ref1, inView: inView1 } = useInView({
        triggerOnce: true,
        onChange: (inView) => inView && handleAnimation('item1'),
    });

    const { ref: ref2, inView: inView2 } = useInView({
        triggerOnce: true,
        onChange: (inView) => inView && handleAnimation('item2'),
    });

    const { ref: ref3, inView: inView3 } = useInView({
        triggerOnce: true,
        onChange: (inView) => inView && handleAnimation('item3'),
    });

    return (
        <div className="dietbox-section">
            <h2>What is <span>Dietbox</span>?</h2>
            <div className="dietbox-features">
                <div
                    ref={ref1}
                    className={`dietbox-feature-item ${hasAnimated.item1 ? 'animate' : ''}`}
                >
                    <h3>No need to shop or chop</h3>
                    <p>You will not need to go near the kitchen! No need for a diet plan, We do it all!</p>
                </div>
                <div
                    ref={ref2}
                    className={`dietbox-feature-item ${hasAnimated.item2 ? 'animate' : ''}`}
                >
                    <h3>Weight loss</h3>
                    <p>At DietBox, every diet food is prepared with healthy fats, proteins, and complex carbohydrates resulting in long-term weight loss.</p>
                </div>
                <div
                    ref={ref3}
                    className={`dietbox-feature-item ${hasAnimated.item3 ? 'animate' : ''}`}
                >
                    <h3>Healthy and natural</h3>
                    <p>At DietBox, meals don’t contain any refined carbohydrates, sugars, or chemical food preservatives.</p>
                </div>
            </div>
        </div>
    );
};

export default DietBoxSection;
