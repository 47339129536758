import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const IntermittentFastingNoon = () => {
    const [selectedPackage, setSelectedPackage] = useState("Intermittent Fasting Noon");
    const navigate = useNavigate();

    // Handle package change
    const handlePackageChange = (event) => {
        setSelectedPackage(event.target.value);
    };

    // Navigate based on selected package
    useEffect(() => {
        switch (selectedPackage) {
            case "Intermittent Fasting Noon":
                navigate("/intermittent-fasting-noon");
                break;
            case "Ninja Plan":
                navigate("/ninja-plan");
                break;
            case "Assassinate Weight":
                navigate("/assassinate-weight");
                break;
            default:
                navigate("/intermittent-fasting-noon");
        }
    }, [selectedPackage, navigate]);

    // Handle add to cart action
    const handleAddToCart = (meal) => {
        // Here you can handle adding the item to the cart
        // For now, we simply navigate to the cart page
        navigate("/cart", { state: { meal } });
    };

    return (
        <div className="intermittent-fasting-noon-container">
            <div className="header-section">
                <h1 className="title">Intermittent Fasting Noon</h1>
                <p className="subtitle">Change Package</p>
                <select 
                    className="package-select" 
                    value={selectedPackage} 
                    onChange={handlePackageChange}
                >
                    <option>Intermittent Fasting Noon</option>
                    <option>Ninja Plan</option>
                    <option> Assassinate Weight</option>
                </select>
                <p className="description">Ideal for those following intermittent fasting with a focus on nutritious meals during the noon period.</p>
            </div>

            <div className="info-section">
                <div className="left-info">
                    <p>Still Confused? Let's talk</p>
                    <button className="whatsapp-button">
                        <i className="fab fa-whatsapp"></i>
                    </button>
                </div>
                <div className="right-info">
                    <p>Buy this Package for 30 Days in just Rs. 29,000</p>
                    <button 
                        className="add-to-cart"
                        onClick={() => handleAddToCart("Intermittent Fasting Noon Package")}
                    >
                        Add To Cart
                    </button>
                </div>
            </div>

            <div className="date-selection">
                <button className="date-button selected">16/Fri</button>
                <button className="date-button">17/Sat</button>
                <button className="date-button">18/Sun</button>
                <button className="date-button">19/Mon</button>
                <button className="date-button">20/Tue</button>
                <button className="date-button">21/Wed</button>
                <button className="date-button">22/Thu</button>
                <button className="date-button">23/Fri</button>
            </div>

            <div className="meal-checkbox">
                <label>
                    <input type="checkbox" className="meal-input"/>
                    Get Whole Day meal on selected date in Rs. 1,200
                </label>
            </div>

            <div className="meal-options">
                <div className="meal-card">
                    <img src="path-to-your-image" alt="Healthy Salad" className="meal-image"/>
                    <p className="meal-title">Healthy Salad <span className="meal-type">Lunch</span> Rs 500</p>
                    <button 
                        className="meal-button" 
                        onClick={() => handleAddToCart("Healthy Salad - Lunch")}
                    >
                        Add To Cart
                    </button>
                </div>
                <div className="meal-card">
                    <img src="path-to-your-image" alt="Grilled Chicken" className="meal-image"/>
                    <p className="meal-title">Grilled Chicken <span className="meal-type">Lunch</span> Rs 500</p>
                    <button 
                        className="meal-button" 
                        onClick={() => handleAddToCart("Grilled Chicken - Lunch")}
                    >
                        Add To Cart
                    </button>
                </div>
                <div className="meal-card">
                    <img src="path-to-your-image" alt="Green Tea" className="meal-image"/>
                    <p className="meal-title">Green Tea <span className="meal-type">Snack</span> Rs 300</p>
                    <button 
                        className="meal-button" 
                        onClick={() => handleAddToCart("Green Tea - Snack")}
                    >
                        Add To Cart
                    </button>
                </div>
            </div>
        </div>
    );
};

export default IntermittentFastingNoon;
